import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  http = inject(HttpClient);
  leads_base_url = environment.leads_base_url;

  sendtrackingData() {
    let trackingData: any = {};
    trackingData.quotationId = localStorage.getItem('quoteid');
    trackingData.googleClickId = localStorage.getItem('gclid');
    trackingData.conversionName = localStorage.getItem('conversionName');
    trackingData.conversionTime = localStorage.getItem('conversionTime');
    trackingData.conversionValue = localStorage.getItem('conversionValue');
    trackingData.conversionCurrency =
      localStorage.getItem('conversionCurrency');
    trackingData.utmSource = localStorage.getItem('utm_source');
    trackingData.utmMedium = localStorage.getItem('utm_medium');
    trackingData.utmCampaign = localStorage.getItem('utm_campaign');
    trackingData.utmContent = localStorage.getItem('utm_content');
    trackingData.utmTerm = localStorage.getItem('utm_term');
    trackingData.utmKeyword = localStorage.getItem('utm_keyword');

    if (trackingData.quotationId) {
      console.log('Send Tracking');
      const body = JSON.stringify(trackingData);
      this.http
        .post<any>(`${this.leads_base_url}tracking`, body, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .subscribe({
          next: () => {},
          error: () => console.log('Error tracking'),
        });
    }
  }

  findKeyInUrl(keyToFind: string) {
    const url = window.location.href;
    if (url.indexOf(keyToFind + '=') === -1) return '';
    const valueFound = url
      .slice(url.indexOf(keyToFind + '=') + keyToFind.length + 1)
      .split('&')[0];
    return valueFound;
  }

  retrieveGoogleClickId(gclickId: string) {
    if (!gclickId) return '';
    let indexOfHash = gclickId.indexOf('#');
    if (indexOfHash !== -1) {
      return gclickId.substring(0, indexOfHash);
    } else {
      return gclickId;
    }
  }

  saveGAVariables() {
    localStorage.clear()
    let googleClickId = this.retrieveGoogleClickId(this.findKeyInUrl('gclid'));
    localStorage.setItem('gclid', googleClickId);
    localStorage.setItem('conversionName', this.findKeyInUrl('conversionName'));
    localStorage.setItem('conversionTime', this.findKeyInUrl('conversionTime'));
    localStorage.setItem(
      'conversionValue',
      this.findKeyInUrl('conversionValue')
    );
    localStorage.setItem(
      'conversionCurrency',
      this.findKeyInUrl('conversionCurrency')
    );
    localStorage.setItem('utm_source', this.findKeyInUrl('utm_source'));
    localStorage.setItem('utm_medium', this.findKeyInUrl('utm_medium'));
    localStorage.setItem('utm_campaign', this.findKeyInUrl('utm_campaign'));
    localStorage.setItem('utm_content', this.findKeyInUrl('utm_content'));
    localStorage.setItem('utm_term', this.findKeyInUrl('utm_term'));
    localStorage.setItem('utm_keyword', this.findKeyInUrl('utm_keyword'));

    // Types of gclid depends campaing source (Google, Facebook, etc)
    if (googleClickId) localStorage.setItem('gclid', googleClickId);
    if (this.findKeyInUrl('gbraid'))
      localStorage.setItem('gclid', this.findKeyInUrl('gbraid'));
    if (this.findKeyInUrl('wbraid'))
      localStorage.setItem('gclid', this.findKeyInUrl('wbraid'));
    if (this.findKeyInUrl('fbclid'))
      localStorage.setItem('gclid', this.findKeyInUrl('fbclid'));
  }
}
