import { ZipCode } from './types';

export const MAIN_BRANDS = [
  'CHEVROLET',
  'CITROEN',
  'FIAT',
  'FORD',
  'HONDA',
  'NISSAN',
  'PEUGEOT',
  'RENAULT',
  'TOYOTA',
  'VOLKSWAGEN',
];

export const MAIN_ZIP_CODES: ZipCode[] = [];

export const QUOTES_TYPE = [
  {
    id: 'A',
    name: 'Responsabilidad civil',
    price: 'Desde $25.000',
  },
  {
    id: 'B',
    name: 'Tercero básico',
    price: 'Desde $35.000',
  },
  {
    id: 'C',
    name: 'Tercero premium',
    price: 'Desde $45.000',
  },
  {
    id: 'D',
    name: 'Todo riesgo',
    price: 'Desde $55.000',
  },
];

export const QUOTE_TABLE_COLUMNS = [
  {
    description: 'Compañía',
  },
  {
    description: 'Suma asegurada',
  },
  {
    description: 'Precio mensual',
  },
];

export const ISSUES_TYPES = [
  { id: 1, description: 'Cobranzas' },
  { id: 2, description: 'Cambio de medio de pago' },
  { id: 3, description: 'Aumento de suma asegurada' },
  { id: 4, description: 'Otros' },
];

export const INSURANCES_TYPES = [
  { id: 1, description: 'Auto' },
  { id: 2, description: 'Moto' },
  { id: 3, description: 'ART' },
  { id: 4, description: 'Flota' },
  { id: 5, description: 'Casa' },
  { id: 6, description: 'Otros' },
];

export const INSURANCES_COMPANIES = [
  { id: 1, description: 'Sancor seguros' },
  { id: 2, description: 'Mercantil Andina' },
  { id: 3, description: 'Integrity seguros' },
  { id: 4, description: 'Provincia seguros' },
  { id: 5, description: 'San cristobal seguros' },
  { id: 6, description: 'Libra' },
  { id: 7, description: 'Galeno seguros' },
  { id: 8, description: 'Otros' },
];

export const OTHER_RISKS_TYPES = [
  { id: 'Seguro técnico', description: 'Seguro técnico' },
  { id: 'Responsabilidad civil', description: 'Responsabilidad civil' },
  { id: 'Cristales', description: 'Cristales' },
  { id: 'Robo', description: 'Robo' },
  { id: 'Vida', description: 'Vida' },
  { id: 'Retiro', description: 'Retiro' },
  { id: 'Salud', description: 'Salud' },
  { id: 'Caución', description: 'Caución' },
  { id: 'Integral de comercio', description: 'Integral de comercio' },
  { id: 'Todo riesgo operativo', description: 'Todo riesgo operativo' },
  {
    id: 'Embarcaciones de placer',
    description: 'Embarcaciones de placer',
  },
];

export const COVERAGE_DETAILS_BY_COMPANY: any = {
  mercantil: {
    A: [
      'Responsabilidad Civil',
      'Asistencia legal',
      'Cobertura en paises limitrofes',
      'Accidentes Personales conductor y/o aseg.',
    ],
    B: [
      'Responsabilidad Civil',
      'Asistencia legal',
      'Cobertura en paises limitrofes',
      'Accidentes Personales conductor y/o aseg.',
      'Robo e Incendio',
      'Gastos de gestoria baja',
      'Grua',
    ],
    C: [
      'Responsabilidad Civil',
      'Asistencia legal',
      'Cobertura en paises limitrofes',
      'Accidentes Personales conductor y/o aseg.',
      'Robo e Incendio',
      'Gastos de gestoria baja',
      'Luneta Parabrisas Vidro en techo',
      'Vidrios leterales y cerradura puertas',
      'Daños parciales al amparo del robo total',
      'Resposicion de cubiertas (con desgaste) afectadas por un siniestro',
      'Resposicion a nuevo de cubiertas afectada por un siniestro',
      'Cobertura adicional por robo de llave',
      'Adicional de GRANIZO parcial sin franquicia',
      'Adicional de Inundacion parcial con franquicia ',
      'Grua',
    ],
    D: [
      'Responsabilidad Civil',
      'Asistencia legal',
      'Cobertura en paises limitrofes',
      'Accidentes Personales conductor y/o aseg.',
      'Robo e Incendio ',
      'Gastos de gestoria baja',
      'Luneta Parabrisas Vidrios laterales , vidrio en techo y cerradura en puertas',
      'Daños parciales al amparo del robo total',
      'Resposicion de cubiertas (con desgaste) afectadas por un siniestro',
      'Resposicion a nuevo de cubiertas afectada por un siniestro',
      'Cobertura adicional por robo de llave',
      'Adicional de GRANIZO parcial sin franquicia',
      'Adicional de Inundacion parcial con franquicia ',
      'Daños parciales con franquicia',
      'Grua',
    ],
  },
  provincia: {
    A: [
      'Responsabilidad Civil',
      'Asistencia Legal',
      'Cobertura en Paises Limitrofes',
      'Grua',
    ],
    B: [
      'Responsabilidad Civil',
      'Asistencia Legal',
      'Cobertura en Paises Limitrofes',
      'Robo e incendio total',
      'Robo e incendio parcial',
      'Grua',
    ],
    C: [
      'Responsabilidad Civil',
      'Asistencia Legal',
      'Cobertura en Paises Limitrofes',
      'Accidentes Personales conductor y/o aseg.',
      'Robo e incendio total',
      'Robo e incendio parcial',
      'Cristales Laterales y Cerraduras.',
      'Daños parciales al amparo del robo total',
      'Robo de rueda',
      'Granizo',
      'Grua',
    ],
    D: [
      'Responsabilidad Civil',
      'Asistencia Legal',
      'Cobertura en Paises Limitrofes',
      'Accidentes Personales conductor y/o aseg.',
      'Robo e incendio total',
      'Robo e incendio parcial',
      'Cristales Laterales y Cerraduras.',
      'Daños parciales al amparo del robo total',
      'Robo de rueda',
      'Granizo',
      'Daño parcial con franquicia',
      'Grua',
    ],
  },
  'san cristobal': {
    A: [
      'Responsabilidad Civil',
      'Cobertura ante muerte o invalidez para conductor',
    ],
    B: [
      'Responsabilidad Civil',
      'Robo total',
      'Incendio Total',
      'Accidente Total',
      'Robo total',
      'Robo Parcial',
      'Incendio Parcial',
      'Robo de Rueda',
      'Gastos por baja del vehiculo',
      'Cobertura ante muerte o invalidez para conductor',
      'Grua',
    ],
    C: [
      'Responsabilidad Civil',
      'Robo total',
      'Incendio Total',
      'Accidente Total',
      'Robo total',
      'Robo Parcial',
      'Incendio Parcial',
      'Cristales Laterales ante accidente y/o incendio',
      'Cristales laterales ante robo',
      'Cerraduras ante accidente y/o robo',
      'Cerradura ante robo',
      'Parabrisas y Lunetas',
      'Cristales Techo',
      'Inundacion',
      'Robo de Rueda',
      'Granizo',
      'Gastos por baja del vehiculo',
      'Daño parcial ante robo total',
      'Cobertura ante muerte o invalidez para conductor',
      'Grua',
    ],
    D: [
      'Responsabilidad Civil',
      'Robo total',
      'Incendio Total',
      'Accidente Total',
      'Robo total',
      'Robo Parcial',
      'Incendio Parcial',
      'Accidente Parcial',
      'Cristales Laterales ante accidente y/o incendio',
      'Cristales laterales ante robo',
      'Cerraduras ante accidente y/o robo',
      'Cerradura ante robo',
      'Parabrisas y Lunetas',
      'Cristales Techo',
      'Inundacion',
      'Robo de Rueda',
      'Granizo',
      'Gastos por baja del vehiculo',
      'Daño parcial ante robo total',
      'Cobertura ante muerte o invalidez para conductor',
      'Grua',
    ],
  },
  sancor: {
    A: [
      'Responsabilidad Civil',
      'Extensión de Cobertura a Países Limítrofes',
      'Asistencia Jurídica',
    ],
    B: [
      'Responsabilidad Civil',
      'Incendio Parcial',
      'Incendio Total',
      'Robo y/o Hurto Parcial',
      'Robo y/o Hurto Total',
      'Lock Out o Tumulto Popular',
      'Destrucción Total al 80%',
      'Extensión de Cobertura a Países Limítrofes',
      'Asistencia Jurídica',
      'Equipo de rastreo',
      'Grua',
    ],
    C: [
      'Responsabilidad Civil',
      'Incendio Parcial',
      'Incendio Total',
      'Robo y/o Hurto Parcial',
      'Robo y/o Hurto Total',
      'Accidente Total',
      'Lock Out o Tumulto Popular',
      'Granizo/Terremoto/Inundac Parc y/o Tot S/Ded',
      'Rotura de Cristales Sin Límite y Sin Deducible',
      'Rotura de Cerraduras Sin Límite y Sin Deducible',
      'Destrucción Total al 80%',
      'Daños Parc. al Amparo de Robo Total S/Deduc',
      'Seguro de Accidentes Personale',
      'Extensión de Cobertura a Países Limítrofes',
      'Asistencia Jurídica',
      'Equipo de rastreo',
      'Grua',
    ],
    D: [
      'Responsabilidad Civil',
      'Incendio Parcial',
      'Incendio Total',
      'Robo y/o Hurto Parcial',
      'Robo y/o Hurto Total',
      'Accidente Total',
      'Accidente Parcial según Deducible',
      'Lock Out o Tumulto Popular',
      'Granizo/Terremoto/Inundac Parc y/o Tot S/Ded',
      'Rotura de Cristales Sin Límite y Sin Deducible',
      'Rotura de Cerraduras Sin Límite y Sin Deducible',
      'Destrucción Total al 80%',
      'Daños Parc. al Amparo de Robo Total S/Deduc',
      'Seguro de Accidentes Personale',
      'Extensión de Cobertura a Países Limítrofes',
      'Asistencia Jurídica',
      'Equipo de rastreo',
      'Grua',
    ],
  },
};

export const GOOGLE_REVIEWS = [
  {
    author: 'Aldo Melian',
    message: `Buena atención, siempre me comunique por Whatsapp y vía mail y la atención es buena.`,
  },
  {
    author: 'Aldo E. Almada',
    message: `Tramite rápido y eficaz, muy buena atencion.`,
  },
  {
    author: 'Fiorella Larocca',
    message: `Excelente atención y súper profesionales. Cuando tuve un problema los llamé, me ayudaron y explicaron todo en detalle.`,
  },
  {
    author: 'Lisandro Sforsini',
    message: `Excelente servicio, pagina muy intuitiva y la respuesta ante cualquier consulta es excelente
`,
  },
];

export const BLOG_POSTS = [
  {
    title: '¿Qué pasará con el servicio de remolque?',
    category: 'Seguridad vial',
    imgUrl: 'https://blog.asegurus.com/wp-content/uploads/2024/05/grua1.jpeg',
    link: 'https://blog.asegurus.com/2024/05/27/que-pasara-con-el-servicio-de-remolque/'
  },
  {
    title: '5 Rutas para viajar por Argentina',
    category: 'Autos y mas',
    imgUrl: 'https://blog.asegurus.com/wp-content/uploads/2024/02/canon.jpeg',
    link: 'https://blog.asegurus.com/2024/02/27/5-rutas-para-viajar-por-argentina/'
  },
  {
    title: '5 Recomendaciones para ahorrar combustible',
    category: 'Autos y mas',
    imgUrl: 'https://blog.asegurus.com/wp-content/uploads/2024/01/ahorrocombu.jpeg',
    link: 'https://blog.asegurus.com/2024/01/08/5-recomendaciones-para-ahorrar-combustible-2/'
  },
];
